import Axios from 'axios';
import { IContent } from 'src/interfaces/Content';
import { Granularity } from 'src/interfaces/Granularity';
import { IMedia } from 'src/interfaces/Media';
import { IServiceAction } from 'src/interfaces/Service';
import { IUser } from 'src/interfaces/User';
import { Roles } from 'src/utils/enum';
import { lowercase, slugify } from 'src/utils/general';
import { apiConfig } from './apiConfig';
import { Query } from 'src/interfaces';

export const axios = Axios.create({
    baseURL: process.env.REACT_APP_STRAPI_URL
});

const headers = () => ({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
});

export const postLogin = async (identifier: string, password: string) => {
    const response = await axios.post(
        apiConfig.auth.login(),
        { identifier, password },
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    // changes companyAdmin-->company owner
    if (lowercase(response?.data?.user?.role?.label) !== lowercase(Roles.CompanyOwner) ){
        throw new Error();
    }
    return response;
};

export const getProfile = async ():Promise<{data:IUser}> => {
    const me = await axios.get(apiConfig.users.me(), { headers: headers() });
    if (!me.data || !me.data.role || lowercase(me.data.role.label) !== lowercase(Roles.CompanyOwner)) {
        throw new Error();
    }
    return me;
};

export const getCompany = async () => {
    const user =  await getProfile();
    return user?.data.company
};

export const getAllContents = async (query:Query)=> {
    const response:any = await axios.get(apiConfig.contents.eligible(query), { headers: headers() });
    const all = response.data.items.map((x: any) => ({
        ...x, 
        state: x.isPublished ? 'published' : 'draft'
    }));
    return {data:all,meta:response.data.meta};
};
export const getPinnedContents = async (): Promise<IContent[]> => {
    const response = await axios.get(apiConfig.contents.pinned(), { headers: headers() });
   
    return response.data;
};

export const getContentById = async (id: string) => {
    return await axios.get(apiConfig.contents.get(id), { headers: headers() });
};

export const getAllUsers = async (query:Query) => {
    const response = await axios.get(apiConfig.users.list(query), { headers: headers() });
    // const all = response.data.map((x: any) => ({
    //     ...x,
    //     state: x.published_at ? 'published' : 'draft'
    // }));
    return response.data;
};

export const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('asset', file);
    const { 'Content-Type': _, ...header } = headers();
    return await axios.post(apiConfig.files.upload_single(), formData, { headers: header });
};

export interface IUpdateCompany {
    name: string;
    color1: string;
    color2: string;
    logo: IMedia;
    logo_small: IMedia;
    code: string;
    pinned_content_1: string | null;
    pinned_content_2: string | null;
    pinned_content_3: string | null;
    pinned_content_4: string | null;
}

export const updateCompany = async (id: string, data: Partial<IUpdateCompany>) => {
    return await axios.put(apiConfig.company.get(id), data, { headers: headers() });
};

export const getEmployeeById = async (id: string) => {
    return await axios.get(apiConfig.users.get(id), { headers: headers() });
};

export interface IUpdateEmployee {
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    joinCode: string;
    language: string;
    country: string;
}
export const updateEmployee = async (id: string, data: Partial<IUpdateEmployee>) => {
    return await axios.put(apiConfig.users.get(id), data, { headers: headers() });
};
export const getCode = async (id: string) => {
    return await axios.get(apiConfig.company.get(id), { headers: headers() });
};

export interface IPostContent {
    title: string;
    description: string;
    mainImage: string;
    duration?: number;
    body?: string;
    audios?: string[];
    videos?: string[];
    link?: string;
    slug?:string;
    type?:string;
}

 export interface IPinnedContent{
      contentId: string;
      order: number;
}
export interface IPostContentData {
    title: string;
    description: string;
    mainImage: IMedia;
    duration?: number;
    body?: string;
    audios?: IMedia[];
    videos?: IMedia[];
    link?: string;
}

export const postContent = async (type: string, data: IPostContent) => {
    return await axios.post(apiConfig.contents.hr(), { ...data, type }, { headers: headers() });
};

export const updateContent = async (id: string, data: IPostContent) => {
    return await axios.put(apiConfig.contents.hr_edit(id), data, { headers: headers() });
};
export const updatePinnedContent = async (data: IPinnedContent[]) => {
    return await axios.put(apiConfig.contents.hr_pinned, {pinnedContents:data}, { headers: headers() });
};

export const deleteContent = async (id: string) => {
    return await axios.delete(apiConfig.contents.hr_edit(id), { headers: headers() });
};

export const publishContentRequest = async (id: string) => {
    return await axios.put(
        apiConfig.contents.publish(id),
        {},
        {
            headers: headers()
        }
    );
};

export const unpublishContentRequest = async (id: string) => {
    return await axios.put(
        apiConfig.contents.unPublish(id),
        {},
        {
            headers: headers()
        }
    );
};

export const getTags = async () => {
    return await axios.get('/tags', { headers: headers() });
};

export const getServices = async (query:Query) => {
    return await axios.get(apiConfig.services.list(query), { headers: headers() });
};

export interface IPostService {
    title: string;
    description: string;
    image: IMedia;
    body?: string;
    ServiceAction: IServiceAction[];
}

export const postService = async (data: IPostService) => {
    const {ServiceAction,...body} = data
    const payload = {
        ...body,
        slug:slugify(data.title),
        image:data.image?.id,
        actions:ServiceAction.map((action,i)=>({...action,order:i}))
    }
    return await axios.post(apiConfig.services.hr.root(),payload,{ headers: headers() });
};

export const getServiceById = async (id: string) => {
    return await axios.get(apiConfig.services.get(id), { headers: headers() });
};

export const deleteService = async (id: string) => {
    return await axios.delete(apiConfig.services.hr.get(id), { headers: headers() });
};

export const updateService = async (id: string, data: IPostService) => {
    const {ServiceAction,...body} = data
    const payload = {
        ...body,
        slug:slugify(data.title),
        image:data.image?.id,
        actions:ServiceAction.map((action,i)=>({...action,order:i}))
    }
    return await axios.put(apiConfig.services.hr.get(id), payload, { headers: headers() });
};

// statistics

export const getUserCount = async () => {
    return await axios.get(
        apiConfig.stats.total_users(),
        {
            headers: headers()
        }
    );
};

export const getTotalContents = async (gran?: Granularity) => {
    return await axios.get(`/statistics/totalContents?gran=${gran}`, { headers: headers() });
};

export const getTotalServices = async (gran?: Granularity) => {
    return await axios.get(`/statistics/totalServices?gran=${gran}`, { headers: headers() });
};

export const getContentViews = async (gran?: Granularity, startDate?: string, endDate?: string) => {
    return await axios.get(
        apiConfig.contents.views({gran: gran ?? "", start:startDate ?? "", end:endDate??""}),
        {
            headers: headers()
        }
    );
};

export const getServiceViews = async (gran?: Granularity, startDate?: string, endDate?: string) => {
    return await axios.get(
        apiConfig.services.views({gran: gran ?? "", start:startDate ?? "", end:endDate??""}),
        {
            headers: headers()
        }
    );
};

export const getPopularTags = async () => {
    return await axios.get('/statistics/popularTags', { headers: headers() });
};

export const getVisits = async (
    gran?: Granularity,
    startDate?: string,
    endDate?: string,
    unique?: boolean
) => {
    return await axios.get(
        apiConfig.stats.visits({gran:gran??"",unique:String(unique) ?? '',start:startDate ?? '',end:endDate ?? ''})
        ,
        {
            headers: headers()
        }
    );
};

export const getPopularContents = async () => {
    return await axios.get('/statistics/popularContent', { headers: headers() });
};

export const getPopularServices = async () => {
    return await axios.get('/statistics/popularServices', { headers: headers() });
};

export interface PushNotification {
    title: string;
    body: string;
}

export const sendPushNotificationRequest = async (data: PushNotification) => {
    return await axios.post('/notification/push', data, { headers: headers() });
};

export const getMonthTotalViews = async () => {
    return await axios.get(apiConfig.stats.views_currentMonth({}), { headers: headers() });
};

export const getMonthTotalVisits = async () => {
    return await axios.get(apiConfig.stats.visits_currentMonth({}), { headers: headers() });
};
