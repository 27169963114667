import { Query } from "src/interfaces";
import { toQuery } from "src/utils/general";

export const apiConfig = {
    baseUrl: process.env.REACT_APP_STRAPI_URL,
    root: {
        roles: (isAdmin: boolean) => `/roles?isAdmin=${isAdmin}`,
        permissions: () => `/permissions/all`,
        update_permissions: (id: number) => `/roles/${id}/permissions`,
    },
    stats: {
        visits: (query: Query) => `/statistics/visits${toQuery(query)}`,
        total_users: () => `/users/employees/count`,
        visits_currentMonth: (query: Query) =>
            `/statistics/visits/current-month${toQuery(query)}`,
        views_currentMonth: (query: Query) =>
            `/statistics/views/current-month${toQuery(query)}`,
    },
    auth: {
        login: () => `/auth/local`,
    },
    company: {
        root: (query?: Query) => `/companies${toQuery(query ?? {})}`,
        list: ({ filter, ...query }: Query) =>
            `/companies/filter?${filter}${toQuery({ ...query }).replace(
                "?",
                "&"
            )}`,
        get: (id: string) => `/companies/${id}`,
        get_csv: (query: Query) => `/companies/csv${toQuery({ ...query })}`,
    },
    contents: {
        root: (query?: Query) => `/contents${toQuery(query ?? {})}`,
        hr: () => `/contents/hr`,
        hr_edit: (id: string) => `/contents/${id}/hr`,
        list: ({ filter, ...query }: Query) =>
            `/contents/ours/filter?${filter}${toQuery({ ...query }).replace(
                "?",
                "&"
            )}`,
        pinned: () => `/contents/pinned/ours`,
        views: (query?: Query) =>
            `/contents/statistics/views${toQuery(query ?? {})}`,
        get: (id: string) => `/contents/${id}`,
        get_translation: (contentId: string) =>
            `/contents/${contentId}/translations`,
        get_csv: (query: Query) => `/contents/csv${toQuery({ ...query })}`,
        publish: (id: string) => `/contents/${id}/publish/hr`,
        unPublish: (id: string) => `/contents/${id}/unpublish/hr`,
        hr_pinned: "/contents/pinned/hr",
        eligible: ({ sort, filter, ...query }: Query) =>
            `/contents/ours/eligible?${filter}&${sort}${toQuery({
                ...query,
            }).replace("?", "&")}`,
        translations: {
            root: () => `/contents/translations`,
            get: (id: string) => `/contents/translations/${id}`,
            get_csv: (query: Query) =>
                `/contents/translations/csv${toQuery({ ...query })}`,
            list: ({ filter, ...query }: Query) =>
                `/contents/translations/filter?${filter}${toQuery({
                    ...query,
                }).replace("?", "&")}`,
        },
    },
    users: {
        root: (query?: Query) => `/users${toQuery(query ?? {})}`,
        list: ({ filter = "", sort, ...query }: Query) =>
            `/users/employees/filter?${filter}&${sort}${toQuery({
                ...query,
            }).replace("?", "&")}`,
        me: () => `/users/me/detailed`,
        get: (id: string) => `/users/${id}/hr`,
        get_csv: (query: Query) => `/users/csv${toQuery({ ...query })}`,
        admins: {
            root: (query?: Query) => `/users/admins${toQuery(query ?? {})}`,
            list: ({ filter, ...query }: Query) =>
                `/users/admins/filter?${filter}${toQuery({ ...query }).replace(
                    "?",
                    "&"
                )}`,
        },
    },
    tags: {
        root: () => `/tags`,
        filter: ({ filter, ...query }: Query) =>
            `/tags/filter?${filter}${toQuery({ ...query }).replace("?", "&")}`,
        get: (tagId: number) => `/tags/${tagId}`,
        get_csv: (query: Query) => `/tags/csv${toQuery({ ...query })}`,
    },
    files: {
        root: (query?: Query) => `/uploads${toQuery(query ?? {})}`,
        upload_single: () => `/uploads/single`,
        get: (key: string) => `/uploads/${key}`,
    },
    services: {
        root: () => `/services`,
        list: ({ filter = "", sort, ...query }: Query) =>
            `/services/ours/filter?${filter}&${sort}${toQuery({
                ...query,
            }).replace("?", "&")}`,
        get: (id: string) => `/services/${id}`,
        hr: {
            root: () => `/services/hr`,
            get: (id: string) => `/services/${id}/hr`,
        },
        get_csv: (query: Query) => `/services/csv${toQuery({ ...query })}`,
        views: (query?: Query) =>
            `/services/statistics/views${toQuery(query ?? {})}`,
        translations: {
            root: () => `/services/translations`,
            list: ({ filter, ...query }: Query) =>
                `/services/translations/filter?${filter}${toQuery({
                    ...query,
                }).replace("?", "&")}`,
            get: (id: string) => `/services/translations/${id}`,
            get_csv: (query: Query) =>
                `/services/translations/csv${toQuery({ ...query })}`,
        },
    },
    categories: {
        root: () => `/categories`,
        list: ({ filter, ...query }: Query) =>
            `/categories/filter?${filter}${toQuery({ ...query }).replace(
                "?",
                "&"
            )}`,
        get: (id: string) => `/categories/${id}`,
        get_csv: (query: Query) => `/categories/csv${toQuery({ ...query })}`,
        translations: {
            root: () => `/categories/translations`,
            list: ({ filter, ...query }: Query) =>
                `/categories/translations/filter?${filter}${toQuery({
                    ...query,
                }).replace("?", "&")}`,
            get: (id: string) => `/categories/translations/${id}`,
        },
    },
    themes: {
        root: () => `/themes`,
        list: ({ filter, ...query }: Query) =>
            `/themes/filter?${filter}${toQuery({ ...query }).replace(
                "?",
                "&"
            )}`,
        get: (id: string) => `/themes/${id}`,
    },
    url: {
        root: () => `/urls`,
        list: ({ filter, ...query }: Query) => `/urls${toQuery({ ...query })}`,
        get: (id: string) => `/urls/${id}`,
    },
};
